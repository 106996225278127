import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { NavigateFunction } from 'react-router-dom';
import {
  apiGetCollection,
  apiGetCollectionRecordings,
  apiGetCollections
} from 'api/v3/collections';
import { V3CollectionAttributes } from 'api/v3/types/collection';
import {
  mapRecordingsToState,
  Recording,
  RecordingMap
} from 'features/recordings/recordingsSlice';
import { Media } from 'api/v3/types/media';

export interface collectionsState {
  collections: CollectionMap;
  currentCollectionId: string | null;
  collectionLoading: boolean;
  collectionRecordingsLoading: boolean;
  nextUrl: null | string;
  pageCount: number;
  paginationStartIds: CollectionPage[];
}

export interface Collection {
  id: string;
  title: string;
  description: string;
  createdAt: string;
  accessLevel: string;
  artworkKey: string | null;
  recordings: RecordingMap;
  recordingsCount: number;
  recordingsNextUrl: null | string;
  recordingsPageCount: number;
  recordingsPaginationStartIds: CollectionRecordingsPage[];
  artwork: Media;
}

export interface CollectionMap {
  [key: string]: Collection;
}

export interface CollectionPage {
  pageNumber: number;
  recordingStartId: number;
}

export interface CollectionRecordingsPage {
  pageNumber: number;
  recordingStartId: number;
}

export const initialCollectionsState: collectionsState = {
  collections: {},
  currentCollectionId: null,
  collectionLoading: false,
  collectionRecordingsLoading: false,
  nextUrl: null,
  pageCount: 0,
  paginationStartIds: []
};

const mapCollectionsToState = (
  collections: V3CollectionAttributes[]
): Collection[] => {
  return collections.map((collection) => {
    return {
      id: collection.id,
      title: collection.attributes.title,
      description: collection.attributes.description,
      createdAt: collection.attributes.created_at,
      accessLevel: collection.attributes.access_level,
      artworkKey: collection.attributes.artwork_key,
      recordings: {},
      recordingsCount: collection.attributes.recordings_count,
      recordingsNextUrl: null,
      recordingsPageCount: 0,
      recordingsPaginationStartIds: [],
      artwork: collection.attributes.media.artwork
    };
  });
};

const collectionsSlice = createSlice({
  name: 'collections',
  initialState: initialCollectionsState,
  reducers: {
    startCollectionLoading(state) {
      state.collectionLoading = true;
    },
    stopCollectionLoading(state) {
      state.collectionLoading = false;
    },
    startCollectionRecordingsLoading(state) {
      state.collectionRecordingsLoading = true;
    },
    stopCollectionRecordingsLoading(state) {
      state.collectionRecordingsLoading = false;
    },
    getCollectionsSuccess(state, action: PayloadAction<Collection[]>) {
      const newCollectionMap: CollectionMap = {};
      action.payload.forEach((collection) => {
        newCollectionMap[collection.id] = collection;
      });
      state.collections = { ...state.collections, ...newCollectionMap };
    },
    getCollectionsFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
    setNextUrl(state, { payload: url }: PayloadAction<string | null>) {
      state.nextUrl = url;
    },
    setPageCount(state, { payload: count }: PayloadAction<number>) {
      state.pageCount = count;
    },
    setPaginationStartIds(state, action: PayloadAction<CollectionPage>) {
      const pageExists =
        state.paginationStartIds.findIndex(
          (page) => page.pageNumber === action.payload.pageNumber
        ) > -1;
      if (!pageExists) {
        state.paginationStartIds.push(action.payload);
      }
    },
    setCurrentCollectionId(state, action: PayloadAction<string | null>) {
      state.currentCollectionId = action.payload;
    },
    getCollectionRecordingsSuccess(state, action: PayloadAction<Recording[]>) {
      if (!state.currentCollectionId) return;
      const newRecordingMap: RecordingMap = {};
      action.payload.forEach((recording) => {
        newRecordingMap[recording.id] = recording;
      });
      state.collections[state.currentCollectionId].recordings = {
        ...state.collections[state.currentCollectionId].recordings,
        ...newRecordingMap
      };
    },
    getCollectionRecordingsFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
    setCollectionRecordingNextUrl(
      state,
      { payload: url }: PayloadAction<string | null>
    ) {
      if (!state.currentCollectionId) return;
      state.collections[state.currentCollectionId].recordingsNextUrl = url;
    },
    setCollectionRecordingPageCount(
      state,
      { payload: count }: PayloadAction<number>
    ) {
      if (!state.currentCollectionId) return;
      state.collections[state.currentCollectionId].recordingsPageCount = count;
    },
    setCollectionRecordingPaginationStartIds(
      state,
      action: PayloadAction<CollectionPage>
    ) {
      if (!state.currentCollectionId) return;
      const pageExists =
        state.collections[
          state.currentCollectionId
        ].recordingsPaginationStartIds.findIndex(
          (page) => page.pageNumber === action.payload.pageNumber
        ) > -1;
      if (!pageExists) {
        state.collections[
          state.currentCollectionId
        ].recordingsPaginationStartIds.push(action.payload);
      }
    },
    resetCollectionsState() {
      return initialCollectionsState;
    }
  }
});

export const {
  getCollectionsSuccess,
  getCollectionsFailed,
  getCollectionRecordingsSuccess,
  getCollectionRecordingsFailed,
  startCollectionLoading,
  stopCollectionLoading,
  startCollectionRecordingsLoading,
  stopCollectionRecordingsLoading,
  setCurrentCollectionId,
  setNextUrl,
  setPageCount,
  setPaginationStartIds,
  setCollectionRecordingNextUrl,
  setCollectionRecordingPageCount,
  setCollectionRecordingPaginationStartIds,
  resetCollectionsState
} = collectionsSlice.actions;

export default collectionsSlice.reducer;

export const fetchCollections =
  (userId: string, pageNumber: number, redirect?: NavigateFunction): AppThunk =>
  async (dispatch, getState) => {
    const {
      collections: { collectionLoading }
    } = getState();
    if (collectionLoading) return;

    dispatch(startCollectionLoading());

    let response;
    try {
      response = await apiGetCollections(userId, pageNumber);
    } catch (err) {
      dispatch(getCollectionsFailed(err ? err.toString() : ''));
      dispatch(stopCollectionLoading());
    }

    if (response) {
      if (redirect && pageNumber > 1 && response.data.length === 0) {
        redirect(`../404`);
        return;
      }

      dispatch(setNextUrl(response.links.next || null));
      dispatch(setPageCount(response.meta.total_pages || 0));
      dispatch(
        setPaginationStartIds({
          pageNumber: pageNumber,
          recordingStartId: +response.data[0]?.id
        })
      );
      dispatch(getCollectionsSuccess(mapCollectionsToState(response.data)));
    }
    dispatch(stopCollectionLoading());
  };

export const fetchCollection =
  (
    channelSubdomain: string,
    collectionId: string,
    navigate?: NavigateFunction,
    hideLoader?: boolean
  ): AppThunk =>
  async (dispatch, getState) => {
    const {
      collections: { collectionLoading }
    } = getState();
    if (collectionLoading) return;

    if (!hideLoader) {
      dispatch(startCollectionLoading());
    }

    let response;
    try {
      response = await apiGetCollection(channelSubdomain, collectionId);
    } catch (err) {
      if (err.status == 404) {
        if (navigate) {
          dispatch(navigateErrorCollection(navigate, false));
        }
      } else {
        dispatch(getCollectionsFailed(err ? err.toString() : ''));
        dispatch(stopCollectionLoading());
      }
    }

    if (response) {
      dispatch(getCollectionsSuccess(mapCollectionsToState([response])));
      dispatch(stopCollectionLoading());
    }
  };

export const fetchCollectionRecordings =
  (
    channelSubdomain: string,
    collectionId: string,
    pageNumber: number,
    redirect?: NavigateFunction
  ): AppThunk =>
  async (dispatch, getState) => {
    const {
      collections: { collectionRecordingsLoading }
    } = getState();
    if (collectionRecordingsLoading) return;

    dispatch(startCollectionRecordingsLoading());

    let response;
    try {
      response = await apiGetCollectionRecordings(
        channelSubdomain,
        collectionId,
        pageNumber
      );
    } catch (err) {
      dispatch(getCollectionsFailed(err ? err.toString() : ''));
      dispatch(stopCollectionRecordingsLoading());
    }

    if (response) {
      if (redirect && pageNumber > 1 && response.data.length === 0) {
        redirect(`../404`);
        return;
      }

      dispatch(setCollectionRecordingNextUrl(response.links.next || null));
      dispatch(setCollectionRecordingPageCount(response.meta.total_pages || 0));
      dispatch(
        setCollectionRecordingPaginationStartIds({
          pageNumber: pageNumber,
          recordingStartId: +response.data[0]?.id
        })
      );
      dispatch(
        getCollectionRecordingsSuccess(mapRecordingsToState(response.data))
      );
    }
    dispatch(stopCollectionRecordingsLoading());
  };

export const navigateErrorCollection =
  (redirect: NavigateFunction, isEmbed: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(stopCollectionLoading());
    if (isEmbed) {
      redirect(`/collections/not-found`);
    } else {
      redirect(`/`);
    }
  };
